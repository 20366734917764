<template>
  <div class="finance-list">
    <gl-title title="结算列表"></gl-title>
    <gl-page-header>
      <el-form :model="mFormData" ref="listForm" :inline="true" size="mini">
        <el-form-item prop="orderId" label="订单搜索">
          <gl-list-search v-model="mFormData.orderId" placeholder="订单ID"></gl-list-search>
        </el-form-item>
        <el-form-item prop="buyerName" label="下单人昵称">
          <gl-list-search v-model="mFormData.buyerName" placeholder="下单人昵称"></gl-list-search>
        </el-form-item>
        <el-form-item prop="isSettlement" label="是否结算">
          <gl-option v-model="mFormData.isSettlement" :list="enumList"></gl-option>
        </el-form-item>
        <el-form-item prop="settlementTimeStart" label="结算时间">
          <gl-date-picker 
            :startTime.sync="mFormData.settlementTimeStart" 
            :endTime.sync="mFormData.settlementTimeEnd">
          </gl-date-picker>
        </el-form-item>
        <el-form-item prop="createTimeStart" label="订单创建时间">
          <gl-date-picker 
            :startTime.sync="mFormData.createTimeStart" 
            :endTime.sync="mFormData.createTimeEnd">
          </gl-date-picker>
        </el-form-item>
        <el-form-item>
          <!-- <el-button  type="primary" icon="el-icon-search" @click="mReload"> 搜索 </el-button> -->
          <el-button  type="primary" icon="el-icon-search" @click="reload"> 搜索 </el-button>
          <el-button icon="el-icon-refresh" @click="mResetForm('listForm')"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </gl-page-header>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" width="44"></el-table-column>
        <el-table-column prop="orderId" label="订单ID"></el-table-column>
        <el-table-column prop="itemName" label="商品标题" width="150"></el-table-column>
        <el-table-column prop="skuName" label="商品规格"></el-table-column>
        <el-table-column prop="buyerName" label="下单人昵称"></el-table-column>
        <el-table-column label="操付款方式作">
          <template slot-scope="{row}">
            <span v-if="row.payType == 1">微信</span>
            <span v-if="row.payType == 2">支付宝</span>
            <span v-if="row.payType == 3">余额</span>
            <span v-if="row.payType == 4">线下支付</span>
          </template>
        </el-table-column>
        <el-table-column prop="totalPrice" label="金额"></el-table-column>
        <el-table-column prop="totalDiscount" label="优惠金额"></el-table-column>
        <el-table-column prop="payPrice" label="实付金额"></el-table-column>
        <el-table-column prop="settleAccounts" label="结算金额"></el-table-column>
        <el-table-column prop="createTime" label="订单时间"></el-table-column>
        <el-table-column prop="settlementTime" label="订单结算时间"></el-table-column>
        <el-table-column prop="isSettlement" label="是否结算">
          <template slot-scope="{row}">
            <span v-if="row.isSettlement == 1">已结算</span>
            <span v-else>未结算</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="130px" fixed="right">
          <template slot-scope="{row}">
            <!-- <el-button type="text" @click="onClickAction('previewVisible', row.orderId)"> 查看 </el-button> -->
            <el-button type="text" @click="$router.push({path: 'detail', query: {itemId: row.orderId}})"> 查看 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange">
        <div class="total-preview">
          <div> 已结算金额：{{money.settled}} </div>
          <div> 未结算金额：{{money.unserrled}} </div>
        </div>
      </gl-pagination>
    </gl-card>
    <!-- <status :itemId="itemId" :visible.sync="orderStatusVisible"/> -->
    <preview :itemId="itemId" :visible.sync="previewVisible"/>
  </div>
</template>

<script>
import ListMixin from '@/mixins/list.mixin.js'
import status from './status.vue'
import preview from './preview.vue'
import {settleList,fetchMoney} from '@/api/finance'
export default {
  name: 'orderListIndex',
  components: {status, preview},
  mixins: [ListMixin],
  data(){
    return{
      previewVisible: false,
      itemId: '',
      enumList: [
        {name: '全部', value: null},
        {name: '已结算', value: '1'},
        {name: '未结算', value: '2'},
      ],
      money: {
        settled: 0,
        unserrled: 0,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
      },
    }
  },
  mounted(){
    this.mGetListFun = settleList
    this.mGetList()

    this.getMoney()
  },
  methods: {
    onClickAction(name, itemId){
      this.itemId = itemId || ''
      this[name] = true
    },
    async getMoney(){
      if(this.mFormData){
        Object.keys(this.mFormData).map(key => {
          this.params[key] = this.mFormData[key]
        })
      }
      try{
        const res = await fetchMoney(this.params)
        if(res.data){
          this.money = res.data 
        }
      }catch(e){

      }
    },
    reload(){
      const formData = {
        pageNum: this.mFormData.pageNum || 1,
        pageSize: this.mFormData.pageSize || 10,
        orderId: this.mFormData.orderId || '',
        isSettlement: this.mFormData.isSettlement || '',
        buyerName: this.mFormData.buyerName || '',
        settlementTimeStart: this.mFormData.settlementTimeStart || '',
        settlementTimeEnd: this.mFormData.settlementTimeEnd || '',
        createTimeStart: this.mFormData.createTimeStart || '',
        createTimeEnd: this.mFormData.createTimeEnd || '',
      }
      this.mGetList()
      this.getMoney()
    },
    handleCurrentChange(val){
      this.params.pageNum = val
      this.mHandleCurrentChange(val)
      this.getMoney()
    },
    handleSizeChange(size){
      this.params.pageSize = size
      this.mHandleSizeChange(size)
      this.getMoney()
    }
  }
}
</script>

<style lang="scss" scoped>
.finance-list{
  .gl-pagination{
    display: flex;
    justify-content: space-between;
    .total-preview{
      width: 120px;
      // color: #909399;
      color: #606266;
      div+div{
        margin-top: 5px;
      }
    }
  }
}
</style>